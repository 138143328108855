import { WinningEntriesTypes } from '_redux/types/winningEntriesTypes';
import { IThunkAction } from 'types/IThunkAction';
import api from 'api';
import { IEntry } from '_redux/reducers/winningEntries';

export const fetchEntriesBySupermarket = (
  supermarket: 'carrefour' | 'geant',
): IThunkAction => async dispatch => {
  const entries = await api.get<IEntry[]>(`entries/${supermarket}`);
  dispatch({ type: WinningEntriesTypes.FETCH_ENTRIES, payload: entries.data });
};
