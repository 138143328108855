import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal as RCTModal,
} from 'reactstrap';
import React, { ReactNode } from 'react';
export interface IModalProps {
  isOpen?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeModal?: (event: React.MouseEvent<any, MouseEvent>) => void;
  footer?: ReactNode;
  className?: string;
  title?: ReactNode;
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  closeModal,
  footer,
  className,
  title,
}) => {
  return (
    <RCTModal isOpen={isOpen} toggle={closeModal} className={className}>
      {title && <ModalHeader toggle={closeModal}>{title}</ModalHeader>}
      <ModalBody>{children}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </RCTModal>
  );
};

export default Modal;
