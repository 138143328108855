import { WinningEntriesTypes } from '_redux/types/winningEntriesTypes';
import { IReduxAction } from 'types/IReduxAction';

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  city: string;
  address: string;
  knownSince: string;
  educationLevel: string;
  birthYear: string;
  CIN: string;
}

export interface IQuizResponse {
  isCorrect: boolean;
  prompt: string;
  answer: string[];
}

export interface IQuizSurveryResponse {
  prompt: string;
  answer: string[];
}

export interface IEntry {
  user: IUser;
  surveryResponses: IQuizSurveryResponse[];
  quizResponses: IQuizResponse[];
  allAnswersAreCorrect: boolean;
  addedBy: number;
}
export interface IWinningEntries {
  entries?: IEntry[];
}

const initialState: IWinningEntries = {};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IWinningEntries => {
  switch (type) {
    case WinningEntriesTypes.FETCH_ENTRIES:
      return { ...state, entries: [...(state.entries || []), ...payload] };
    default:
      return state;
  }
};
