import { Helmet } from 'react-helmet';
import React from 'react';
import getCurrentLocation from 'helpers/getCurrentLocation';
import { useLocation } from 'react-router-dom';

interface ISEOProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  // url ?: string;
  siteName?: string;
  author?: string;
  twitterUrl?: string;
}
const SEO: React.FC<ISEOProps> = ({
  title = 'El Business pour vos annonces professionnelles',
  description = 'El Business pour vos annonces professionnelles',
  image = 'https://i.imgur.com/t5Rv0B7.jpg',
  type = 'article',
  // url = 'http://el-business.edonec.online',
  siteName = 'El Business',
  author = 'El Business',
  twitterUrl = 'http://el-business.edonec.online',
}) => {
  const location = useLocation();
  const url = getCurrentLocation(location.pathname);

  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <title>{title}</title>

      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="robots" content="index,follow" />

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      <meta itemProp="picture" content={image} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="fr_TN" />
      <meta property="article:author" content={author} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterUrl} />
      <meta name="twitter:creator" content={twitterUrl} />

      <meta
        httpEquiv="Content-Type"
        content="text/html; charset=Windows-1256"
      />
    </Helmet>
  );
};

export default SEO;
