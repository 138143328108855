import React from 'react';
import SEO from 'components/SEO';
import NotFoundHeader from 'components/NotFoundHeader';
import { NotFoundSVG } from 'components/SVG';

const NotFound: React.FC = () => {
  return (
    <>
      <SEO title="Not Found page" />
      <NotFoundHeader />
      <NotFoundSVG className="not-found-svg z-index-2" />
    </>
  );
};

export default NotFound;
