import axios from 'axios';
export const CLIENT_API = 'https://quiz-bic-api.edonec.com/api/v1';

const api = axios.create({
  baseURL: CLIENT_API,
});

export const resetAuthToken = async (token: string) => {
  if (token) {
    api.defaults.headers.Authorization = `bearer ${token.trim()}`;
  } else {
    api.defaults.headers.Authorization = null;
  }
};
export default api;
