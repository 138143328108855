import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Root } from 'containers';
import { ToastContainer } from 'react-toastify';
import store from '_redux/store';

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      toastClassName="text-font-primary font-weight-bold rounded"
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      closeButton={false}
    />
    <Router>
      <Switch>
        <Route path="/" component={Root} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
