import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from '_redux/reducers';
import { useSelector } from 'react-redux';

const LoggedOutRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Route path={path} exact={exact} component={component} />
  );
};
export default LoggedOutRoute;
