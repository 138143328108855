export const carrefourGits = [
  { image: 'smartwatch.jpg', value: 'Montre connectée' },
  { image: 'tablet.jpg', value: 'Tablette' },
  { image: 'phone.jpg', value: 'IPhone 15' },
];
export const geantGifts = [
  { image: 'smartwatch.jpg', value: 'Montre connectée' },
  { image: 'tablet.jpg', value: 'Tablette' },
  { image: 'playstation.jpg', value: 'Playstation 5' },
];
