import { combineReducers } from 'redux';
import loading from './loading';
import auth from './auth';
import winningEntries from './winningEntries';

const rootReducer = combineReducers({
  loading,
  auth,
  winningEntries
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
