import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Geant from 'pages/Geant';
import Carrefour from 'pages/Carrefour';
import NotFound from 'pages/NotFound';
import LoggedOutRoute from './LoggedOutRoute';

const App: React.FC = () => {
  return (
    <Switch>
      <LoggedOutRoute exact path="/" component={Geant} />
      <LoggedOutRoute exact path="/tunis-city" component={Geant} />
      <LoggedOutRoute exact path="/carrefour" component={Carrefour} />
      <Route component={NotFound} />
    </Switch>
  );
};
export default App;
