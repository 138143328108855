import { AuthTypes } from '_redux/types/authTypes';
import { IReduxAction } from 'types/IReduxAction';

export interface IAuthState {
  isLoggedIn: boolean;
}

const initialState: IAuthState = {
  isLoggedIn: false,
};

export default (state = initialState, { type }: IReduxAction): IAuthState => {
  switch (type) {
    case AuthTypes.USER_LOGGED_IN:
      return { isLoggedIn: true };
    case AuthTypes.USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
};
