import React from 'react';

const Description: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => (
  <div className="d-flex justify-content-between">
    <span className="font-size-2 font-weight-bold text-uppercase">{`${title} :`}</span>
    <span className="font-size-2 font-weight-bolder text-uppercase">
      {value}
    </span>
  </div>
);
interface IWinnerCardProps {
  phone: string;
  giftImage: string;
  gift: string;
  adress: string;
  cin: string;
  city: string;
  fullName: string;
}
const WinnerCard: React.FC<IWinnerCardProps> = ({
  cin,
  fullName,
  phone,
  gift,
  giftImage,
  city,
}) => {
  return (
    <div className="mt-11 card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover">
      <div className="row align-items-center">
        <div className="col-8 col-md-8">
          <div className="py-4 pl-4">
            <h2 className="h2 text-uppercase">{fullName}</h2>
            <Description title="CIN" value={cin} />
            <Description title="Ville" value={city} />
            <Description title="Telephone" value={phone} />
            <Description title="Cadeau" value={gift} />
          </div>
        </div>
        <img
          className="image-fit col-4"
          alt={gift}
          src={`/assets/images/${giftImage}`}
        />
      </div>
    </div>
  );
};

export default WinnerCard;
