/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEntriesBySupermarket } from '_redux/actions';
import { RootState } from '_redux/reducers';
import { getWinnersList } from 'helpers/getWinnersList';
import WinnerCard from 'components/WinnerCard';
import { carrefourGits, geantGifts } from 'constants/gifts';
import { IUser } from '_redux/reducers/winningEntries';
import Modal from 'components/Modal';
import Loader from 'react-loader-spinner';

const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [winnerId, setWinnerId] = useState(0);
  const [finalWinners, setFinalWinners] = useState<IUser[]>([]);

  useEffect(() => {
    dispatch(fetchEntriesBySupermarket('carrefour'));
    dispatch(fetchEntriesBySupermarket('geant'));
  }, []);

  const entries = useSelector(({ winningEntries }: RootState) => ({
    users: winningEntries.entries?.map(o => o.user),
    cins: winningEntries.entries?.map(o => o.user.CIN),
  }));
  const [winnersCarrefourCINS, setWinnersCarrefourCINS] = useState<
    string[] | undefined
  >();

  useEffect(() => {
    if (entries.cins?.length)
      setWinnersCarrefourCINS(getWinnersList(entries, 'carrefour'));
  }, [entries.cins?.length]);

  const winnersCarrefour =
    winnersCarrefourCINS &&
    (winnersCarrefourCINS.map(o =>
      entries.users?.find(f => f.CIN === o),
    ) as IUser[]);

  const userToProps = (
    o: IUser | undefined,
    i: number,
    gifts: typeof carrefourGits | typeof geantGifts,
  ) => ({
    key: o?.CIN,
    phone: o?.phoneNumber || '',
    giftImage: gifts[i < carrefourGits.length ? i : 0].image,
    gift: gifts[i < carrefourGits.length ? i : 0].value,
    adress: o?.address || '',
    cin: o?.CIN || '',
    city: o?.city || '',
    fullName: `${o?.firstName} ${o?.lastName}`,
  });
  const openModal = () => {
    setIsOpen(true);
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  };
  return (
    <>
      <div className="clearfix m-4">
        <div
          className=""
          style={{
            height: '70vh',
            backgroundImage: 'url(/assets/images/hero.jpg)',
            backgroundSize: 'cover',
          }}
        />
      </div>
      <div className="container">
        {finalWinners.length < carrefourGits.length && (
          <>
            <div className="">
              <h1>Faire le tirage au sort (Carrefour)</h1>
              <span>
                (les cadeaux seront ordonnés du moins valeureux au plus
                valeureux)
              </span>
            </div>

            <button
              onClick={openModal}
              type="button"
              className="btn btn-dark mt-7 mb-11 "
            >
              Generer
            </button>
          </>
        )}
        {winnerId > 0 && winnersCarrefour && (
          <>
            <h1>Les Gagnants Finaux</h1>
            {finalWinners.map((o, i) => (
              <WinnerCard {...userToProps(o, i, carrefourGits)} />
            ))}
          </>
        )}
        <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div>
                <Loader type="Rings" color="#00BFFF" height={400} width={400} />
                <h2 className="text-center">À la recherche du gagnant</h2>
              </div>
            </div>
          ) : (
            winnersCarrefour && (
              <div>
                <h2 className="text-center">Le gagnant est:</h2>
                <WinnerCard
                  {...userToProps(
                    winnersCarrefour[winnerId],
                    winnerId,
                    carrefourGits,
                  )}
                />
                <div className="d-flex justify-content-center">
                  {typeof winnersCarrefour !== 'undefined' && (
                    <button
                      onClick={() => {
                        setIsOpen(false);
                        setFinalWinners([
                          ...finalWinners,
                          winnersCarrefour[winnerId] as IUser,
                        ]);
                        winnerId < geantGifts.length &&
                          setTimeout(() => setWinnerId(winnerId + 1), 2000);
                      }}
                      type="button"
                      className="btn btn-dark mt-7 mb-11 "
                    >
                      Ajouter
                    </button>
                  )}
                </div>
              </div>
            )
          )}
        </Modal>
      </div>
    </>
  );
};

export default HomePage;
