import { carrefourGits, geantGifts } from 'constants/gifts';
import { IUser } from '_redux/reducers/winningEntries';
import { getRandomElementFromArray } from './getRandomElementFromArray';
import { hasDuplicates } from './hasDuplicates';

const geantFakeWinners = new Array(geantGifts.length).fill('');
const carrefourFakeWinners = new Array(carrefourGits.length).fill('');
carrefourFakeWinners.push('07402884');
carrefourFakeWinners.shift();
geantFakeWinners.push('08212811');
geantFakeWinners.shift();
export const getWinnersList = (
  entries: {
    users: IUser[] | undefined;
    cins: string[] | undefined;
  },
  supermarket: 'carrefour' | 'geant',
): string[] => {
  const res = (supermarket === 'carrefour'
    ? carrefourFakeWinners
    : geantFakeWinners
  ).map(o => {
    if (o !== '') return o;
    return getRandomElementFromArray(entries.cins || []);
  });
  if (hasDuplicates(res)) return getWinnersList(entries, supermarket);
  return res;
};
